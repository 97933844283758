// @mui material components
import { Grid } from "@mui/material";

// Components
import { AddDireccionSujetoExcluido } from "../atomos";

export const Row5SujetoExcluido = () => {
  return (
    <Grid container wrap="nowrap" gap={5} flexDirection={{ xs: "column", lg: "row" }}>
      <AddDireccionSujetoExcluido />
    </Grid>
  );
};
