// @mui material components
import { Grid } from "@mui/material";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "../../../../ShortCutsLabels";

// Material Dashboard 2 PRO React TS components
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

// Hooks
import { useAddDireccionSujetoExcluido } from "./useAddDireccionSujetoExcluido";

export const AddDireccionSujetoExcluido = () => {
  const { keys, DireccionSujetoExcluido, errorsDireccionSujetoExcluido, touchedDireccionSujetoExcluido, direccionSujetoExcluido, onBlurDireccionSujetoExcluido, handleChange } = useAddDireccionSujetoExcluido();
  return (
    <Grid item xs={12}>
      <MDBox position="relative">
        <MDInput ref={DireccionSujetoExcluido} fullWidth onBlur={onBlurDireccionSujetoExcluido} sx={{ input: { paddingY: "1.04rem" } }} label="Direccion*" name="sujetoExcluido.direccion" value={direccionSujetoExcluido} onChange={handleChange} />
        <ShortCutsLabels
          keys={keys}
          command={[
            { key: "control", label: "CTRL" },
            { key: "c", label: "C" },
          ]}
        />
        <MDBox position="absolute" width="100%">
          <CustomFieldError touched={touchedDireccionSujetoExcluido ? true : false} errorName={errorsDireccionSujetoExcluido as string} />
        </MDBox>
      </MDBox>
    </Grid>
  );
};
