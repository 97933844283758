import { useContext, useEffect, useRef } from "react";

// Context
import { KeyboardContext } from "context/KeyboardContext";
import { ShortcutContext } from "context/ShortcutContext";
import { useFormikContext } from "formik";

// Types
import { DocumentType } from "types/documentType";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

export const useAutoCompleteTipoDoc = () => {
  const { setValues, values, errors, touched, setFieldTouched } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  /*****************************************
   * Constantes
   *****************************************/

  const tipoDocSujetoExcluido = values.sujetoExcluido?.tipoDoc;
  const errorsTipoDocSujetoExcluido = errors.sujetoExcluido?.tipoDoc;
  const touchedTipoDocSujetoExcluido = touched.sujetoExcluido?.tipoDoc;

  const optionsTipoDocSujetoExcluido = [DocumentType.NRC, DocumentType.DUI, DocumentType.NIT, DocumentType.PASSPORT, DocumentType.CARNET_RESIDENTE];

  /*****************************************
   * Funciones
   *****************************************/
  const handleChangeTipoDoc = (newValue: string) => {
    setValues((prev) => ({
      ...prev,
      sujetoExcluido: {
        ...prev.sujetoExcluido,
        tipoDoc: newValue,
        documento: newValue !== prev.sujetoExcluido?.tipoDoc ? "" : prev.sujetoExcluido?.documento,
      },
    }));
  };
  const onBlurTipoDocSujetoExcluido = () => {
    setFieldTouched("sujetoExcluido.tipoDoc", true);
  };

  /*****************************************
   * Atajos de teclado
   *****************************************/
  const { keys } = useContext(KeyboardContext);
  const { registerListener, triggerListener } = useContext(ShortcutContext);
  const TipoDocSujetoExcluido = useRef<HTMLInputElement>();

  useEffect(() => {
    registerListener(new Set(["control", "o"]), () => {
      if (TipoDocSujetoExcluido.current) {
        TipoDocSujetoExcluido.current.focus();
        TipoDocSujetoExcluido.current.click();
      }
    });
  }, [triggerListener]);

  return { onBlurTipoDocSujetoExcluido, handleChangeTipoDoc, TipoDocSujetoExcluido, keys, tipoDocSujetoExcluido, errorsTipoDocSujetoExcluido, touchedTipoDocSujetoExcluido, optionsTipoDocSujetoExcluido };
};
