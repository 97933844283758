// @mui material components
import { Autocomplete, Grid, TextField } from "@mui/material";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "../../../../ShortCutsLabels";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Hooks
import { useAutoCompleteMunicipioSujetoExcluido } from "./useAutoCompleteMunicipioSujetoExcluido";

export const AutoCompleteMunicipioSujetoExcluido = () => {
  const { keys, MunicipioSujetoExcluido, errorsMunicipioSujetoExcluido, touchedMunicipioSujetoExcluido, municipioSujetoExcluido, onBlurMunicipioSujetoExcluido, handleChangeMunicipioSujetoExcluido, municipios } =
    useAutoCompleteMunicipioSujetoExcluido();
  return (
    <Grid item width={{ xs: "100%", lg: "50%" }}>
      <Autocomplete
        openOnFocus
        fullWidth
        ref={MunicipioSujetoExcluido}
        value={municipioSujetoExcluido}
        renderInput={(params) => (
          <MDBox position="relative">
            <TextField {...params} label="Municipio*" />
            <ShortCutsLabels
              keys={keys}
              command={[
                { key: "control", label: "CTRL" },
                { key: "m", label: "M" },
              ]}
            />
            <MDBox position="absolute" width="100%">
              <CustomFieldError touched={touchedMunicipioSujetoExcluido ? true : false} errorName={errorsMunicipioSujetoExcluido as string} />
            </MDBox>
          </MDBox>
        )}
        onChange={(_, newValue) => {
          handleChangeMunicipioSujetoExcluido(newValue);
        }}
        onBlur={onBlurMunicipioSujetoExcluido}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        options={municipios}
      />
    </Grid>
  );
};
