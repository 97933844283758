// @mui material components
import { Autocomplete, Grid, TextField } from "@mui/material";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "../../../../ShortCutsLabels";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Hooks
import { useAutoCompleteDepartamentoSujetoExcluido } from "./useAutoCompleteDepartamentoSujetoExcluido";

export const AutoCompleteDepartamentoSujetoExcluido = () => {
  const { keys, DepartamentoSujetoExcluido, errorsDepartamentoSujetoExcluido, touchedDepartamentoSujetoExcluido, departamentoSujetoExcluido, onBlurDepartamentoSujetoExcluido, handleChangeDepartamentoSujetoExcluido, departamentos } =
    useAutoCompleteDepartamentoSujetoExcluido();
  return (
    <Grid item width={{ xs: "100%", lg: "50%" }}>
      <Autocomplete
        openOnFocus
        ref={DepartamentoSujetoExcluido}
        fullWidth
        value={departamentoSujetoExcluido}
        renderInput={(params) => (
          <MDBox position="relative">
            <TextField {...params} label="Departamento*" />
            <ShortCutsLabels
              keys={keys}
              command={[
                { key: "control", label: "CTRL" },
                { key: "d", label: "D" },
              ]}
            />
            <MDBox position="absolute" width="100%">
              <CustomFieldError touched={touchedDepartamentoSujetoExcluido ? true : false} errorName={errorsDepartamentoSujetoExcluido as string} />
            </MDBox>
          </MDBox>
        )}
        onChange={(_, newValue) => {
          handleChangeDepartamentoSujetoExcluido(newValue);
        }}
        onBlur={onBlurDepartamentoSujetoExcluido}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        options={departamentos}
      />
    </Grid>
  );
};
