import { useContext, useEffect, useRef } from "react";

// Context
import { useFormikContext } from "formik";
import { KeyboardContext } from "context/KeyboardContext";
import { ShortcutContext } from "context/ShortcutContext";

// Utils
import { getMunicipios } from "utils/getRegions";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

export const useAutoCompleteMunicipioSujetoExcluido = () => {
  const { values, errors, touched, setFieldTouched, setFieldValue } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  /*****************************************
   * Constantes
   *****************************************/
  const departamentoSujetoExcluido = values.sujetoExcluido?.departamento;
  const municipioSujetoExcluido = values.sujetoExcluido?.municipio;
  const errorsMunicipioSujetoExcluido = errors.sujetoExcluido?.municipio;
  const touchedMunicipioSujetoExcluido = touched.sujetoExcluido?.municipio;

  const municipios = departamentoSujetoExcluido ? getMunicipios(departamentoSujetoExcluido.id) : [];

  /*****************************************
   * Funciones
   *****************************************/

  const onBlurMunicipioSujetoExcluido = () => {
    setFieldTouched("sujetoExcluido.municipio", true);
  };

  const handleChangeMunicipioSujetoExcluido = (newValue: { id: string; label: string }) => {
    setFieldValue("sujetoExcluido.municipio", newValue);
  };

  /*****************************************
   * Atajos de teclado
   *****************************************/
  const { keys } = useContext(KeyboardContext);
  const { registerListener, triggerListener } = useContext(ShortcutContext);
  const MunicipioSujetoExcluido = useRef<HTMLInputElement>();

  useEffect(() => {
    registerListener(new Set(["control", "m"]), () => {
      if (MunicipioSujetoExcluido.current) {
        MunicipioSujetoExcluido.current.focus();
        MunicipioSujetoExcluido.current.click();
      }
    });
  }, [triggerListener]);
  return { MunicipioSujetoExcluido, keys, onBlurMunicipioSujetoExcluido, handleChangeMunicipioSujetoExcluido, municipioSujetoExcluido, errorsMunicipioSujetoExcluido, touchedMunicipioSujetoExcluido, municipios };
};
