// @mui material components
import { Grid } from "@mui/material";

// Components
import { AutoCompleteActividadSujetoExcluido } from "../atomos";
export const Row3SujetoExcluido = () => {
  return (
    <Grid container wrap="nowrap" gap={5} flexDirection={{ xs: "column", lg: "row" }}>
      <AutoCompleteActividadSujetoExcluido />
    </Grid>
  );
};
