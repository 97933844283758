import { useContext, useEffect, useRef } from "react";

// Context
import { useFormikContext } from "formik";
import { KeyboardContext } from "context/KeyboardContext";
import { ShortcutContext } from "context/ShortcutContext";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { DocumentType } from "types/documentType";

export const useAddDocumentSujetoExcluido = () => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  /*****************************************
   * Constantes
   *****************************************/
  const tipoDocSujetoExcluido = values.sujetoExcluido?.tipoDoc;
  const documentoSujetoExcluido = values.sujetoExcluido?.documento;
  const errorsAddDocument = errors.sujetoExcluido?.documento;
  const touchedAddDocument = touched.sujetoExcluido?.documento;

  /*****************************************
   * Funciones
   *****************************************/
  const handleChangeFormat = (e: any) => {
    if (values.sujetoExcluido?.tipoDoc === DocumentType.NIT) {
      e.currentTarget.maxLength = 14;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{4})(\d{6})(\d{3})(\d{1})/, "$1-$2-$3-$4");
      setFieldValue("sujetoExcluido.documento", value);
    }
    if (values.sujetoExcluido?.tipoDoc === DocumentType.DUI) {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{8})(\d{1})/, "$1-$2");
      setFieldValue("sujetoExcluido.documento", value);
    }
    if (values.sujetoExcluido?.tipoDoc === DocumentType.NRC) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d+)(\d{1})/, "$1-$2");
      setFieldValue("sujetoExcluido.documento", value);
    }
    if (values.sujetoExcluido?.tipoDoc === DocumentType.PASSPORT) {
      setFieldValue("sujetoExcluido.documento", e.currentTarget.value);
    }
  };
  const onBlurAddDocument = () => {
    setFieldTouched("sujetoExcluido.documento", true);
  };

  /*****************************************
   * Atajos de teclado
   *****************************************/
  const { keys } = useContext(KeyboardContext);
  const { registerListener, triggerListener } = useContext(ShortcutContext);
  const DocumentoSujetoExcluido = useRef<HTMLInputElement>();

  useEffect(() => {
    registerListener(new Set(["control", "j"]), () => {
      if (DocumentoSujetoExcluido.current) {
        DocumentoSujetoExcluido.current.focus();
        DocumentoSujetoExcluido.current.click();
      }
    });
  }, [triggerListener]);

  return { keys, DocumentoSujetoExcluido, handleChangeFormat, onBlurAddDocument, errorsAddDocument, touchedAddDocument, documentoSujetoExcluido, tipoDocSujetoExcluido };
};
