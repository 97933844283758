// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Components
import { AtomTitleSujetoExcluido } from "../atomos";

export const TitleSujetoExcluido = () => {
  return (
    <MDBox>
      <AtomTitleSujetoExcluido />
    </MDBox>
  );
};
