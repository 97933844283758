// Context
import { useAddSellContext } from "context/AddSellContext";
import { useFormikContext } from "formik";

// Custom Components
import GeneralDteFilters from "./generalDte";
import AddDetailsDteFilter from "./addDetailsDte";
import Receiver from "../Receiver";
import AddDetailsDteFilterSimple from "layouts/ventas/nuevoDTESimple/components/filters/AddDetailsDteFilterSimple";
import OtherDocumentData from "./OtherDocument";
import OtherInfoDte from "./OtherInfoDte";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { MINIMUM_AMOUNT } from "types/minimumAmount";
import { SujetoExcluido } from "./SujetoExcluido";
import { IvaDocumentTypes } from "types/iva-document";

export interface NewDteFiltersProps {
  freeSale?: boolean;
  refs?: {
    DetailFilter: React.MutableRefObject<HTMLInputElement>;
    DetailFilterSimple: React.MutableRefObject<HTMLInputElement>;
  };
  emails?: string;
}
const NewDteFilters = ({ emails, freeSale, refs }: NewDteFiltersProps) => {
  const {
    global: { totalToPay },
  } = useAddSellContext();

  const { values } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  return (
    <>
      <GeneralDteFilters emails={emails} />
      {values?.IvaDocumentType?.name_document === IvaDocumentTypes.FSE ? <SujetoExcluido /> : null}
      {values?.IvaDocumentType?.name_document === IvaDocumentTypes.FEX ? (
        <>
          <OtherDocumentData />
          <OtherInfoDte />
        </>
      ) : (
        <></>
      )}
      {values?.IvaDocumentType ? (
        values?.IvaDocumentType?.name_document === IvaDocumentTypes.FEX ? (
          values.type ? (
            freeSale ? (
              <AddDetailsDteFilterSimple refDetailFilterSimple={refs?.DetailFilterSimple} />
            ) : (
              <AddDetailsDteFilter refDetailFilter={refs?.DetailFilter} />
            )
          ) : null
        ) : freeSale ? (
          <AddDetailsDteFilterSimple refDetailFilterSimple={refs?.DetailFilterSimple} />
        ) : (
          <AddDetailsDteFilter refDetailFilter={refs?.DetailFilter} />
        )
      ) : null}
      {totalToPay >= MINIMUM_AMOUNT.STANDARD_MINIMUN_AMOUNT ? <Receiver /> : <></>}
    </>
  );
};

export default NewDteFilters;
