import { useContext, useEffect, useRef } from "react";

// Context
import { KeyboardContext } from "context/KeyboardContext";
import { ShortcutContext } from "context/ShortcutContext";
import { useFormikContext } from "formik";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

// Utils
import { departamentos } from "utils/getRegions";

export const useAutoCompleteDepartamentoSujetoExcluido = () => {
  const { values, errors, touched, setFieldTouched, setFieldValue } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  /*****************************************
   * Constantes
   *****************************************/
  const departamentoSujetoExcluido = values.sujetoExcluido?.departamento;
  const errorsDepartamentoSujetoExcluido = errors.sujetoExcluido?.departamento;
  const touchedDepartamentoSujetoExcluido = touched.sujetoExcluido?.departamento;

  /*****************************************
   * Funciones
   *****************************************/

  const onBlurDepartamentoSujetoExcluido = () => {
    setFieldTouched("sujetoExcluido.departamento", true);
  };

  const handleChangeDepartamentoSujetoExcluido = (newValue: { id: string; label: string }) => {
    setFieldValue("sujetoExcluido.departamento", newValue);
    setFieldValue("sujetoExcluido.municipio", null);
  };

  /*****************************************
   * Atajos de teclado
   *****************************************/
  const { keys } = useContext(KeyboardContext);
  const { registerListener, triggerListener } = useContext(ShortcutContext);
  const DepartamentoSujetoExcluido = useRef<HTMLInputElement>();

  useEffect(() => {
    registerListener(new Set(["control", "d"]), () => {
      if (DepartamentoSujetoExcluido.current) {
        DepartamentoSujetoExcluido.current.focus();
        DepartamentoSujetoExcluido.current.click();
      }
    });
  }, [triggerListener]);
  return { keys, DepartamentoSujetoExcluido, departamentos, onBlurDepartamentoSujetoExcluido, handleChangeDepartamentoSujetoExcluido, departamentoSujetoExcluido, errorsDepartamentoSujetoExcluido, touchedDepartamentoSujetoExcluido };
};
