// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";

export const AtomTitleSujetoExcluido = () => {
  return (
    <MDTypography color="info" variant="h4">
      Agregar Datos del Sujeto Excluido
    </MDTypography>
  );
};
