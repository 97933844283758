// @mui material components
import { Card, Grid } from "@mui/material";

// Components
import { Row1SujetoExcluido, Row2SujetoExcluido, Row3SujetoExcluido, Row4SujetoExcluido, Row5SujetoExcluido, TitleSujetoExcluido } from "./components/moleculas";

export const SujetoExcluido = () => {
  return (
    <Grid item xs={12}>
      <Card sx={{ padding: 5 }}>
        <TitleSujetoExcluido />
        <Grid container gap={{ xs: 5, lg: 7 }} my={5} mb={1}>
          <Row1SujetoExcluido />
          <Row2SujetoExcluido />
          <Row3SujetoExcluido />
          <Row4SujetoExcluido />
          <Row5SujetoExcluido />
        </Grid>
      </Card>
    </Grid>
  );
};
