// @mui material components
import { Grid } from "@mui/material";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "../../../../ShortCutsLabels";

// Material Dashboard 2 PRO React TS components
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

// Hooks
import { useAddNameSujetoExcluido } from "./useAddNameSujetoExcluido";

export const AddNameSujetoExcluido = () => {
  const { keys, NombreSujetoExcluido, errorsNombreSujetoExcluido, touchedNombreSujetoExcluido, nombreSujetoExcluido, onBlurNombreSujetoExcluido, handleChange } = useAddNameSujetoExcluido();
  return (
    <Grid item xs={12} lg={4}>
      <MDBox position="relative">
        <MDInput ref={NombreSujetoExcluido} fullWidth onBlur={onBlurNombreSujetoExcluido} sx={{ input: { paddingY: "1.04rem" } }} label="Nombre Sujeto Excluido" name="sujetoExcluido.nombre" value={nombreSujetoExcluido} onChange={handleChange} />
        <ShortCutsLabels
          keys={keys}
          command={[
            { key: "control", label: "CTRL" },
            { key: "n", label: "N" },
          ]}
        />
        <MDBox position="absolute" width="100%">
          <CustomFieldError touched={touchedNombreSujetoExcluido ? true : false} errorName={errorsNombreSujetoExcluido as string} />
        </MDBox>
      </MDBox>
    </Grid>
  );
};
