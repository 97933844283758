import { useContext, useEffect, useRef } from "react";

// Context
import { KeyboardContext } from "context/KeyboardContext";
import { ShortcutContext } from "context/ShortcutContext";
import { useFormikContext } from "formik";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

export const useAddNameSujetoExcluido = () => {
  const { values, errors, touched, setFieldTouched, handleChange } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  /*****************************************
   * Constantes
   *****************************************/
  const nombreSujetoExcluido = values.sujetoExcluido?.nombre;
  const errorsNombreSujetoExcluido = errors.sujetoExcluido?.nombre;
  const touchedNombreSujetoExcluido = touched.sujetoExcluido?.nombre;

  /*****************************************
   * Funciones
   *****************************************/

  const onBlurNombreSujetoExcluido = () => {
    setFieldTouched("sujetoExcluido.nombre", true);
  };

  /*****************************************
   * Atajos de teclado
   *****************************************/
  const { keys } = useContext(KeyboardContext);
  const { registerListener, triggerListener } = useContext(ShortcutContext);
  const NombreSujetoExcluido = useRef<HTMLInputElement>();

  useEffect(() => {
    registerListener(new Set(["control", "n"]), () => {
      if (NombreSujetoExcluido.current) {
        NombreSujetoExcluido.current.focus();
        NombreSujetoExcluido.current.click();
      }
    });
  }, [triggerListener]);

  return { keys, NombreSujetoExcluido, nombreSujetoExcluido, errorsNombreSujetoExcluido, touchedNombreSujetoExcluido, handleChange, onBlurNombreSujetoExcluido };
};
