// @mui material components
import { Autocomplete, Grid, TextField } from "@mui/material";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "../../../../ShortCutsLabels";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Hooks
import { useAutoCompleteActividadSujetoExcluido } from "./useAutoCompleteActividadSujetoExcluido";

export const AutoCompleteActividadSujetoExcluido = () => {
  const {
    keys,
    ActividadEconomicaSujetoExcluido,
    errorsActividadEconomicaSujetoExcluido,
    touchedActividadEconomicaSujetoExcluido,
    actividadEconomicaSujetoExcluido,
    onBlurActividadEconomicaSujetoExcluido,
    handleChangeActividadEconomicaSujetoExcluido,
    actividadComercial,
  } = useAutoCompleteActividadSujetoExcluido();
  return (
    <Grid item xs={12}>
      <Autocomplete
        options={actividadComercial || []}
        openOnFocus
        fullWidth
        ref={ActividadEconomicaSujetoExcluido}
        onChange={(_, newValue) => {
          handleChangeActividadEconomicaSujetoExcluido(newValue);
        }}
        onBlur={onBlurActividadEconomicaSujetoExcluido}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        value={actividadEconomicaSujetoExcluido}
        renderInput={(params) => (
          <MDBox position="relative">
            <TextField {...params} label="Actividad Principal" />
            <ShortCutsLabels
              keys={keys}
              command={[
                { key: "control", label: "CTRL" },
                { key: "a", label: "A" },
              ]}
            />
            <MDBox position="absolute" width="100%">
              <CustomFieldError touched={touchedActividadEconomicaSujetoExcluido ? true : false} errorName={errorsActividadEconomicaSujetoExcluido as string} />
            </MDBox>
          </MDBox>
        )}
      />
    </Grid>
  );
};
