// @mui material components
import { Grid } from "@mui/material";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "../../../../ShortCutsLabels";

// Material Dashboard 2 PRO React TS components
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

// Hooks
import { useAddDocumentSujetoExcluido } from "./useAddDocumentSujetoExcluido";

export const AddDocumentSujetoExcluido = () => {
  const { keys, DocumentoSujetoExcluido, errorsAddDocument, touchedAddDocument, documentoSujetoExcluido, onBlurAddDocument, tipoDocSujetoExcluido, handleChangeFormat } = useAddDocumentSujetoExcluido();
  return (
    <Grid item xs={12} lg={6}>
      <MDBox position="relative">
        <MDInput
          fullWidth
          ref={DocumentoSujetoExcluido}
          sx={{ input: { paddingY: "1.04rem" } }}
          onBlur={onBlurAddDocument}
          disabled={!Boolean(tipoDocSujetoExcluido)}
          label="Documento de identificación"
          value={documentoSujetoExcluido}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeFormat(e);
          }}
        />
        <ShortCutsLabels
          keys={keys}
          command={[
            { key: "control", label: "CTRL" },
            { key: "j", label: "J" },
          ]}
        />
        <MDBox position="absolute" width="100%">
          <CustomFieldError touched={touchedAddDocument ? true : false} errorName={errorsAddDocument as string} />
        </MDBox>
      </MDBox>
    </Grid>
  );
};
