// @mui material components
import { Autocomplete, Grid, TextField } from "@mui/material";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "../../../../ShortCutsLabels";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Hooks
import { useAutoCompleteTipoDoc } from "./useAutoCompleteTipoDoc";

export const AutoCompleteTipoDoc = () => {
  const { errorsTipoDocSujetoExcluido, touchedTipoDocSujetoExcluido, handleChangeTipoDoc, tipoDocSujetoExcluido, onBlurTipoDocSujetoExcluido, optionsTipoDocSujetoExcluido, keys, TipoDocSujetoExcluido } = useAutoCompleteTipoDoc();

  return (
    <Grid item xs={12} lg={6}>
      <Autocomplete
        openOnFocus
        ref={TipoDocSujetoExcluido}
        fullWidth
        value={tipoDocSujetoExcluido}
        renderInput={(params) => (
          <MDBox position="relative">
            <TextField {...params} label="Tipo de Documento" />
            <ShortCutsLabels
              keys={keys}
              command={[
                { key: "control", label: "CTRL" },
                { key: "o", label: "O" },
              ]}
            />
            <MDBox position="absolute" width="100%">
              <CustomFieldError touched={touchedTipoDocSujetoExcluido ? true : false} errorName={errorsTipoDocSujetoExcluido as string} />
            </MDBox>
          </MDBox>
        )}
        onChange={(_, newValue) => {
          handleChangeTipoDoc(newValue);
        }}
        onBlur={onBlurTipoDocSujetoExcluido}
        isOptionEqualToValue={(option: string, value: string) => option === value}
        options={optionsTipoDocSujetoExcluido}
      />
    </Grid>
  );
};
