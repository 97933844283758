// @mui material components
import { Grid } from "@mui/material";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "../../../../ShortCutsLabels";

// Material Dashboard 2 PRO React TS components
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

// Hooks
import { useAddEmailSujetoExcluido } from "./useAddEmailSujetoExcluido";

export const AddEmailSujetoExcluido = () => {
  const { keys, EmailSujetoExcluido, errorsEmailSujetoExcluido, touchedEmailSujetoExcluido, emailSujetoExcluido, onBlurEmailSujetoExcluido, handleChange } = useAddEmailSujetoExcluido();
  return (
    <Grid item xs={12} lg={4}>
      <MDBox position="relative">
        <MDInput ref={EmailSujetoExcluido} fullWidth onBlur={onBlurEmailSujetoExcluido} sx={{ input: { paddingY: "1.04rem" } }} label="Email Sujeto Excluido" name="sujetoExcluido.email" value={emailSujetoExcluido} onChange={handleChange} />
        <ShortCutsLabels
          keys={keys}
          command={[
            { key: "control", label: "CTRL" },
            { key: "e", label: "E" },
          ]}
        />
        <MDBox position="absolute" width="100%">
          <CustomFieldError touched={touchedEmailSujetoExcluido ? true : false} errorName={errorsEmailSujetoExcluido as string} />
        </MDBox>
      </MDBox>
    </Grid>
  );
};
