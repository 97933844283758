import { useContext, useEffect, useRef } from "react";

// Context
import { KeyboardContext } from "context/KeyboardContext";
import { ShortcutContext } from "context/ShortcutContext";
import { useFormikContext } from "formik";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

export const useAddDireccionSujetoExcluido = () => {
  const { values, errors, touched, setFieldTouched, handleChange } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  /*****************************************
   * Constantes
   *****************************************/
  const direccionSujetoExcluido = values.sujetoExcluido?.direccion;
  const errorsDireccionSujetoExcluido = errors.sujetoExcluido?.direccion;
  const touchedDireccionSujetoExcluido = touched.sujetoExcluido?.direccion;

  /*****************************************
   * Funciones
   *****************************************/

  const onBlurDireccionSujetoExcluido = () => {
    setFieldTouched("sujetoExcluido.direccion", true);
  };

  /*****************************************
   * Atajos de teclado
   *****************************************/
  const { keys } = useContext(KeyboardContext);
  const { registerListener, triggerListener } = useContext(ShortcutContext);
  const DireccionSujetoExcluido = useRef<HTMLInputElement>();

  useEffect(() => {
    registerListener(new Set(["control", "c"]), () => {
      if (DireccionSujetoExcluido.current) {
        DireccionSujetoExcluido.current.focus();
        DireccionSujetoExcluido.current.click();
      }
    });
  }, [triggerListener]);

  return { keys, DireccionSujetoExcluido, handleChange, onBlurDireccionSujetoExcluido, direccionSujetoExcluido, errorsDireccionSujetoExcluido, touchedDireccionSujetoExcluido };
};
