// @mui material components
import { Grid } from "@mui/material";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "../../../../ShortCutsLabels";

// Material Dashboard 2 PRO React TS components
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

// Hooks
import { useAddTelefonoSujetoExcluido } from "./useAddTelefonoSujetoExcluido";

export const AddTelefonoSujetoExcluido = () => {
  const { keys, TelefonoSujetoExcluido, errorsTelefonoSujetoExcluido, touchedTelefonoSujetoExcluido, telefonoSujetoExcluido, onBlurTelefonoSujetoExcluido, handleChange } = useAddTelefonoSujetoExcluido();
  return (
    <Grid item xs={12} lg={4}>
      <MDBox position="relative">
        <MDInput
          ref={TelefonoSujetoExcluido}
          fullWidth
          onBlur={onBlurTelefonoSujetoExcluido}
          sx={{ input: { paddingY: "1.04rem" } }}
          label="Telefono Sujeto Excluido"
          name="sujetoExcluido.telefono"
          value={telefonoSujetoExcluido}
          onChange={handleChange}
        />
        <ShortCutsLabels
          keys={keys}
          command={[
            { key: "control", label: "CTRL" },
            { key: "f", label: "F" },
          ]}
        />
        <MDBox position="absolute" width="100%">
          <CustomFieldError touched={touchedTelefonoSujetoExcluido ? true : false} errorName={errorsTelefonoSujetoExcluido as string} />
        </MDBox>
      </MDBox>
    </Grid>
  );
};
