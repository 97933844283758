import { useContext, useEffect, useRef } from "react";

// Context
import { KeyboardContext } from "context/KeyboardContext";
import { ShortcutContext } from "context/ShortcutContext";
import { useFormikContext } from "formik";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

export const useAddTelefonoSujetoExcluido = () => {
  const { values, errors, touched, setFieldTouched, handleChange } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  /*****************************************
   * Constantes
   *****************************************/
  const telefonoSujetoExcluido = values.sujetoExcluido?.telefono;
  const errorsTelefonoSujetoExcluido = errors.sujetoExcluido?.telefono;
  const touchedTelefonoSujetoExcluido = touched.sujetoExcluido?.telefono;

  /*****************************************
   * Funciones
   *****************************************/

  const onBlurTelefonoSujetoExcluido = () => {
    setFieldTouched("sujetoExcluido.telefono", true);
  };

  /*****************************************
   * Atajos de teclado
   *****************************************/
  const { keys } = useContext(KeyboardContext);
  const { registerListener, triggerListener } = useContext(ShortcutContext);
  const TelefonoSujetoExcluido = useRef<HTMLInputElement>();

  useEffect(() => {
    registerListener(new Set(["control", "f"]), () => {
      if (TelefonoSujetoExcluido.current) {
        TelefonoSujetoExcluido.current.focus();
        TelefonoSujetoExcluido.current.click();
      }
    });
  }, [triggerListener]);

  return { keys, TelefonoSujetoExcluido, errorsTelefonoSujetoExcluido, touchedTelefonoSujetoExcluido, telefonoSujetoExcluido, handleChange, onBlurTelefonoSujetoExcluido };
};
