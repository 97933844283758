import { useContext, useEffect, useRef } from "react";

// Context
import { KeyboardContext } from "context/KeyboardContext";
import { ShortcutContext } from "context/ShortcutContext";
import { useFormikContext } from "formik";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

// Utils
import { actividadComercial } from "utils/getActividadPrincipal";

export const useAutoCompleteActividadSujetoExcluido = () => {
  const { values, errors, touched, setFieldTouched, setFieldValue } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  /*****************************************
   * Constantes
   *****************************************/
  const actividadEconomicaSujetoExcluido = values.sujetoExcluido?.actividadEconomica;
  const errorsActividadEconomicaSujetoExcluido = errors.sujetoExcluido?.actividadEconomica;
  const touchedActividadEconomicaSujetoExcluido = touched.sujetoExcluido?.actividadEconomica;

  /*****************************************
   * Funciones
   *****************************************/

  const onBlurActividadEconomicaSujetoExcluido = () => {
    setFieldTouched("sujetoExcluido.actividadEconomica", true);
  };

  const handleChangeActividadEconomicaSujetoExcluido = (newValue: { id: string; label: string }) => {
    setFieldValue("sujetoExcluido.actividadEconomica", newValue);
  };

  /*****************************************
   * Atajos de teclado
   *****************************************/
  const { keys } = useContext(KeyboardContext);
  const { registerListener, triggerListener } = useContext(ShortcutContext);
  const ActividadEconomicaSujetoExcluido = useRef<HTMLInputElement>();

  useEffect(() => {
    registerListener(new Set(["control", "a"]), () => {
      if (ActividadEconomicaSujetoExcluido.current) {
        ActividadEconomicaSujetoExcluido.current.focus();
        ActividadEconomicaSujetoExcluido.current.click();
      }
    });
  }, [triggerListener]);

  return {
    keys,
    ActividadEconomicaSujetoExcluido,
    handleChangeActividadEconomicaSujetoExcluido,
    actividadComercial,
    onBlurActividadEconomicaSujetoExcluido,
    actividadEconomicaSujetoExcluido,
    errorsActividadEconomicaSujetoExcluido,
    touchedActividadEconomicaSujetoExcluido,
  };
};
